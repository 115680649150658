import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.navbar');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router } from '@angular/router';

@Component({
    selector: 'wiz-layout-navbar',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig-survey/project/main/build/src/app/layout.navbar/view.scss */
body {
  width: 100%;
}`],
})
export class LayoutNavbarComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         public router: Router    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.currentRoute = this.router.url;
    }
}

export default LayoutNavbarComponent;