import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.ocr.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-ocr-list',
template: templateSource || '',
    styles: [``],
})
export class PageOcrListComponent implements OnInit {
    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        let session = this.service.auth.session;
        if (Object.keys(session).length === 0) {
            this.service.href(`/`);
            return;
        }
        this.userRole = session.role;
        
        await this.load();
        await this.service.render();
    }

    public async load() {
        let { code, data } = await wiz.call("load");
        if (code == 200) {
            this.list = data;
        }
    }

    public async edit(id) {
        location.href = `/ocr/edit/${id}`;
    }
}

export default PageOcrListComponent;